import { AxiosRequestConfig } from "axios";
import compact from "lodash/compact";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";

import { DIProps } from "@di";

import { ApiSpecModels } from "@models/apiSpec";
import { ApronAlert, IncidentSummary } from "@models/apronAlert";
import { FutureFlight, FutureFlightV2 } from "@models/flightInfo";
import {
  IncidentSeverity,
  SAFETY_TYPES,
  SafetyAlertIncidentConfig,
  SafetyEventIncidentConfig,
  SafetyType,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";
import { TOperationWidget } from "@models/operationWidget";
import { GlobalStand } from "@models/stand";
import { IWatchlist, IWatchlistData } from "@models/watchlist";
import { camelCaseKeys, filterItemsByStandPatterns } from "@services/data";

import { generateLabelById } from "@services/standLabelUtils";
import { isFiniteNumber } from "@services/utils";

import {
  getActiveTurnaroundsApiConfig,
  getFetchDetectionApiConfig,
  getFullTurnaroundApiConfig,
  getIncidentsSummaryApiConfig,
  getStandsApiConfig,
  getStandTurnaroundsApiConfig,
  getTimelineApiConfig,
  getTurnaroundTimelineApiConfig,
  RequestParams,
  searchTurnaroundsApiConfig,
} from "../configs";
import {
  parseAlert,
  parseCameraOutage,
  parseDetection,
  parseDetections,
  parseIncidentConfig,
  parsePartialDetection,
  parsePts,
  parseTimestamps,
  parseTimestampsDict,
  parseTurnaround,
  parseTurnarounds,
} from "../parsers";
import {
  AlertsParams,
  TAddAlertsResult,
  TimelineResponse,
  TLoadedIncidentsResult,
  TLoadedSafetyEventsResult,
  TurnaroundResponse,
  TurnaroundTimelineResponse,
  WatchlistTurnaroundState,
} from "../types";
import { TTurnaroundsParams } from "./types";
import { toMilliSecondsOrNull } from "@services/time";
import { createTurnaroundParams } from "@models/turnaround";
import { TurnaroundParams } from "@models/TurnaroundParams";
import { prepareTurnaroundParams } from "@api/parsers/prepareTunraroundParams";

export class Api {
  constructor(private _di: DIProps) {
    console.log("init api");
  }

  get standPatterns() {
    return this._di.config.standPatterns;
  }

  private get _request() {
    return this._di.apiClient.performRequest;
  }

  getStands() {
    const { config } = getStandsApiConfig();

    interface IRawStand {
      id: string;
      camera_ids: string[];
    }

    type TResponse = IRawStand[];

    return this._request<GlobalStand[], TResponse>(config, (rawStands = []) => {
      return rawStands.map((rawStand) => {
        const generatedStandLabel = generateLabelById(rawStand.id);
        const label = generatedStandLabel;

        const cameras = orderBy(
          rawStand.camera_ids,
          [(id) => id.indexOf("-s"), (id) => id],
          ["desc", "asc"]
        ).map((c: string) => {
          let generatedCameraLabel = generateLabelById(c);

          // Replace stand label part in camera with the actual stand label
          generatedCameraLabel = generatedCameraLabel.replace(
            generatedStandLabel,
            label
          );

          return {
            id: c,
            label: generatedCameraLabel,
          };
        });

        return {
          id: rawStand.id,
          label,
          cameras,
        };
      });
    });
  }

  getStandTurnarounds(standId: string, params: RequestParams) {
    const { config } = getStandTurnaroundsApiConfig(standId, {
      turnaroundsRequestLimit: this._di.config.turnaroundsRequestLimit,
      params,
    });

    return this._request(config, this._parseTurnarounds);
  }

  searchTurnarounds(
    stand: string | null | undefined,
    query: string,
    before?: number,
    limit = this._di.config.turnaroundsRequestLimit
  ) {
    if (!query) {
      return Promise.resolve({ turnarounds: [], fetchedCount: 0 });
    }

    const { config } = searchTurnaroundsApiConfig({
      stand,
      query,
      before,
      limit,
    });

    return this._request(config, this._parseTurnarounds);
  }

  getTurnaround(standId: string, turnId: string) {
    const { config } = getFullTurnaroundApiConfig(standId, turnId);

    type TResponse = {
      turnaround: ApiSpecModels["TurnaroundData"];
      current_ts: number;
      last_image_ts: number;
      last_prediction_ts: number;
      online: Record<string, boolean>;
    };

    return this._request<TurnaroundResponse | null, TResponse>(
      config,
      (data) => {
        if (!data) {
          return null;
        }

        const { detections, pts, ...rest } = data.turnaround;

        const turnaround = this._parseTurnaround(rest);

        if (!turnaround) {
          return null;
        }

        return {
          ...parseTimestamps(data),
          online: data.online,
          turnaround,
          pts: parsePts(pts),
          detections: detections?.map(parsePartialDetection) || [],
        };
      }
    );
  }

  timeline(standId: string, startTs: number, endTs?: number | null) {
    const { config } = getTimelineApiConfig(standId, startTs, endTs);

    type TResponse = any;

    return this._request<TimelineResponse, TResponse>(config, (data) => ({
      ...parseTimestamps(data),
      turnarounds: this._parseTurnarounds(data.turnarounds).turnarounds,
      detections: parseDetections(
        data.detections,
        standId,
        parsePartialDetection
      ),
      outages: data.camera_outages.map(parseCameraOutage),
    }));
  }

  turnaroundTimeline(standId: string, turnId: string) {
    const { config } = getTurnaroundTimelineApiConfig(standId, turnId);

    type TResponse = ApiSpecModels["TurnaroundTimeline"];

    return this._request<TurnaroundTimelineResponse | null, TResponse>(
      config,
      (data) => {
        const parsedTurnaround = this._parseTurnaround(data.turnaround);

        if (!parsedTurnaround) {
          return null;
        }

        return {
          ...parseTimestamps(data),
          turnaround: parsedTurnaround,
          detections: parseDetections(
            data.detections || [],
            standId,
            parsePartialDetection
          ),
          outages: data.camera_outages?.map(parseCameraOutage) || [],
        };
      }
    );
  }

  fetchDetection(id: string) {
    const { config } = getFetchDetectionApiConfig(id);

    return this._request(config, parseDetection);
  }

  getActiveTurnarounds(lastRequestTs?: number) {
    const { config } = getActiveTurnaroundsApiConfig(lastRequestTs);
    return this._request(config, this._parseTurnarounds);
  }

  getFlights(to?: number) {
    const config: AxiosRequestConfig = {
      url: "/api/flights/",
      params: {},
    };

    if (to) {
      config.params.to = Math.ceil(to / 1000);
    }

    type TResponse = {
      scheduled_off_block_utc: number;
      estimated_off_block_utc: number;
      stand_id: string;
      flight_number: string;
    }[];

    return this._request<FutureFlight[], TResponse>(config, (data) => {
      const items = data.map((flight) => ({
        standId: flight.stand_id,
        flightNumber: flight.flight_number,
        sobt: toMilliSecondsOrNull(flight.scheduled_off_block_utc),
        eobt: toMilliSecondsOrNull(flight.estimated_off_block_utc),
        sibt: null,
        eibt: null,
      }));

      return sortBy(items, ({ eobt, sobt }) => eobt || sobt);
    });
  }

  async getAlertsForSidebar(
    params: AlertsParams = {},
    options: {
      filterAlertsFromOtherAirports: boolean;
    }
  ): Promise<TLoadedIncidentsResult> {
    const result = await this._getAlertsHandler(
      params,
      ["unified", "safety-alert"],
      options.filterAlertsFromOtherAirports
    );

    return {
      alerts: [result.unifiedIncidents, result.safetyAlertIncidents].flat(),
      actualAlertCount: result.actualAlertCount,
    };
  }

  async getSafetyEvents(
    params: AlertsParams = {},
    filterAlertsFromOtherAirports = true
  ): Promise<TLoadedSafetyEventsResult> {
    const result = await this._getAlertsHandler(
      params,
      ["safety-event", "weighted-safety-event"],
      filterAlertsFromOtherAirports
    );

    return {
      safetyEvents: result.safetyEventIncidents,
      actualAlertCount: result.actualAlertCount,
    };
  }

  getAlertsConfigList() {
    const config: AxiosRequestConfig = {
      url: "/api/incident_configs/safety_events/",
    };

    type TResult = {
      unifiedConfigs: UnifiedIncidentConfig[];
      safetyAlertConfigs: SafetyAlertIncidentConfig[];
      safetyEventConfigs: SafetyEventIncidentConfig[];
    };

    type TResponse = unknown[];

    return this._request<TResult, TResponse>(config, (rawConfigs = []) => {
      const result: TResult = {
        unifiedConfigs: [],
        safetyAlertConfigs: [],
        safetyEventConfigs: [],
      };

      for (const raw of rawConfigs) {
        const parsedData = parseIncidentConfig(raw);
        const data = parsedData.data;

        switch (data.incidentType) {
          case "unified": {
            result.unifiedConfigs.push({
              ...parsedData,
              data,
            });
            break;
          }

          case "safety-alert": {
            result.safetyAlertConfigs.push({
              ...parsedData,
              data,
            });
            break;
          }

          case "safety-event": {
            // TODO / FIXME: It is unreliable to use customText to determine chocks, speed and etc.

            let safetyType: SafetyType = SAFETY_TYPES.DEFAULT;
            if (parsedData.customText?.includes(":")) {
              const [customSafetyType = SAFETY_TYPES.DEFAULT, customText = ""] =
                parsedData.customText.split(":") as [SafetyType, string];

              safetyType = customSafetyType;
              parsedData.customText = customText;
            }

            if (data.type === "weighted-safety-event") {
              safetyType = SAFETY_TYPES.WEIGHTED;
            }

            if (data.type === "pushback-angle-safety-event") {
              safetyType = SAFETY_TYPES.TOWBAR_ANGLE;
            }

            result.safetyEventConfigs.push({
              ...parsedData,
              safetyType,
              data,
            });
            break;
          }
        }
      }

      return result;
    });
  }

  makeExport(standId: string, startTs: number, endTs: number) {
    const config: AxiosRequestConfig = {
      url: `/api/stands/${standId}/export`,
      timeout: 60 * 5 * 1000,
      params: {
        start_ts: startTs / 1000,
        end_ts: endTs / 1000,
      },
    };

    type Result = Pick<TimelineResponse, "detections" | "turnarounds">;

    return this._request<Result>(config, (data) => ({
      detections: parseDetections(data.detections, standId, parseDetection),
      turnarounds: this._parseTurnarounds(data.turnarounds).turnarounds,
    }));
  }

  getIncidentsSummary = () => {
    const { config } = getIncidentsSummaryApiConfig();

    type TResponse = ApiSpecModels["IncidentSummary"][];

    return this._request<Record<string, IncidentSummary>, TResponse>(
      config,
      (data) => {
        const result: IncidentSummary[] = [];

        for (const { stand_id, incidents } of data) {
          if (!stand_id || !incidents) {
            continue;
          }

          const keyedSummary: Partial<IncidentSummary["keyedSummary"]> = {};

          const parsedIncidents: IncidentSummary["incidents"] = [];

          for (const { severity, count } of incidents) {
            if (!severity || !count) {
              continue;
            }

            keyedSummary[severity as unknown as IncidentSeverity] = count;

            parsedIncidents.push({
              severity: severity as unknown as IncidentSeverity,
              count,
            });
          }

          result.push({
            standId: stand_id,
            incidents: parsedIncidents,
            keyedSummary,
          });
        }

        const filteredResult = filterItemsByStandPatterns(
          result,
          (v) => v.standId,
          this.standPatterns
        );

        return keyBy(filteredResult, (v) => v.standId);
      }
    );
  };

  loadImageAjax = async (url: string) => {
    const resp = await fetch(url);

    if (!resp.ok) {
      throw resp;
    }

    const blob = await resp.blob();

    return URL.createObjectURL(blob);
  };

  getWatchlist() {
    return this._request<IWatchlist | void>({
      url: "/api/v2/user-watchlist/current",
    });
  }

  createWatchlist(data: IWatchlistData) {
    return this._request<IWatchlist>({
      method: "POST",
      url: "/api/v2/user-watchlist/current",
      data,
    });
  }

  removeWatchlist() {
    return this._request<void>({
      method: "DELETE",
      url: "/api/v2/user-watchlist/current",
    });
  }

  getWatchlistActiveTurnarounds() {
    return this._getTurnaroundsV2({
      watchlist: true,
      authorized: true,
      active: true,
    });
  }

  getActiveTurnaroundsV2(
    props: {
      watchlist?: boolean;
    },
    withSummary: boolean,
    signal?: AbortSignal
  ) {
    return this._getTurnaroundsV2(
      {
        active: true,
        watchlist: props.watchlist,
      },
      withSummary,
      signal
    );
  }

  getHistoricTurnaroundsV2(
    props: {
      watchlist?: boolean;
      turnFilter: TTurnaroundsParams["turn_start_filter"];
      sort: TTurnaroundsParams["sort"];
      standFilter?: TTurnaroundsParams["stand_filter"];
      paramFilters?: TTurnaroundsParams["param_filters"];
      inboundFlightNumberFilters?: TTurnaroundsParams["inbound_flight_number_filters"];
      outboundFlightNumberFilters?: TTurnaroundsParams["outbound_flight_number_filters"];
      inboundDepartureAirportFilters?: TTurnaroundsParams["inbound_departure_airport_filters"];
      outboundArrivalAirportFilters?: TTurnaroundsParams["outbound_arrival_airport_filters"];
      acNumberFilters?: TTurnaroundsParams["ac_number_filters"];
      regNumberFilters?: TTurnaroundsParams["reg_number_filters"];
      farmsStatusFilters?: TTurnaroundsParams["farms_status_filters"];
    },
    withSummary: boolean,
    signal?: AbortSignal
  ) {
    return this._getTurnaroundsV2(
      {
        active: false,
        turn_start_filter: props.turnFilter,
        watchlist: props.watchlist,
        sort: props.sort,
        stand_filter: props.standFilter,
        param_filters: props.paramFilters,
        inbound_flight_number_filters: props.inboundFlightNumberFilters,
        outbound_flight_number_filters: props.outboundFlightNumberFilters,
        inbound_departure_airport_filters: props.inboundDepartureAirportFilters,
        outbound_arrival_airport_filters: props.outboundArrivalAirportFilters,
        ac_number_filters: props.acNumberFilters,
        reg_number_filters: props.regNumberFilters,
        farms_status_filters: props.farmsStatusFilters,
      },
      withSummary,
      signal
    );
  }

  async getTurnaroundWidgets(id: string) {
    return await this._request<TOperationWidget[]>(
      { method: "GET", url: `/api/v2/turnarounds/${id}/operation-widgets` },
      camelCaseKeys
    );
  }

  getInboundOutboundFlights(
    type: "arrivals" | "departures",
    params: {
      from?: number;
      to?: number;
      flightNumbers?: string[];
      watchlist?: boolean;
    },
    signal?: AbortSignal
  ): Promise<FutureFlightV2[]> {
    type TResponse = {
      scheduled_off_block_utc: number | null;
      estimated_off_block_utc: number | null;
      scheduled_on_block_utc: number | null;
      estimated_on_block_utc: number | null;

      stand_id: string;
      company_iata: string | null;
      origin_date: string | null;
      departure_airport: string | null;
      arrival_airport: string | null;
      aircraft_type: string | null;
      aircraft_reg_number: string | null;
      raw_flight_status: string | null;
      full_flight_number: string | null;

      /**
       *  aibt
       */
      actual_on_block_utc: number | null;

      /**
       *  tibt
       */
      target_on_block_utc: number | null;

      /**
       *  aldt
       */
      actual_landing_time: number | null;

      /**
       *  aobt
       */
      actual_off_block_utc: number | null;

      /**
       *  aircraft_ready_ts
       */
      aircraft_ready_utc: number | null;

      /**
       *  tsat
       */
      target_start_up_approval_time: number | null;

      /**
       *  skip
       */
      actual_pushback_given_time: number | null;

      /**
       *  asat
       */
      actual_start_up_approval_time: number | null;

      /**
       *  asrt
       */
      actual_start_up_request_time: number | null;

      /**
       *  ctot
       */
      calculated_take_off_time: number | null;

      /**
       *  tobt
       */
      target_off_block_utc: number | null;

      /**
       *  skip
       */
      target_take_off_time: number | null;

      /**
       *  ltd
       */
      latest_time_of_departure: number | null;
    }[];

    const payload = {
      flight_numbers: params.flightNumbers,
      from: isFiniteNumber(params.from) ? params.from / 1000 : undefined,
      to: isFiniteNumber(params.to) ? params.to / 1000 : undefined,
      watchlist: params.watchlist,
    };

    return this._request<FutureFlightV2[], TResponse>(
      {
        method: "POST",
        url: `/api/v2/flights/${type}`,
        data: payload,
        signal,
      },
      (data) => {
        return data.map((flight) => {
          const parsedParams: TurnaroundParams = {
            ...createTurnaroundParams(),
            sobt: toMilliSecondsOrNull(flight.scheduled_off_block_utc),
            eobt: toMilliSecondsOrNull(flight.estimated_off_block_utc),
            sibt: toMilliSecondsOrNull(flight.scheduled_on_block_utc),
            eibt: toMilliSecondsOrNull(flight.estimated_on_block_utc),
            aibt: toMilliSecondsOrNull(flight.actual_on_block_utc),
            aldt: toMilliSecondsOrNull(flight.actual_landing_time),
            aobt: toMilliSecondsOrNull(flight.actual_off_block_utc),
            aircraft_ready_ts: toMilliSecondsOrNull(flight.aircraft_ready_utc),
            tsat: toMilliSecondsOrNull(flight.target_start_up_approval_time),
            asat: toMilliSecondsOrNull(flight.actual_start_up_approval_time),
            asrt: toMilliSecondsOrNull(flight.actual_start_up_request_time),
            ctot: toMilliSecondsOrNull(flight.calculated_take_off_time),
            tobt: toMilliSecondsOrNull(flight.target_off_block_utc),
            ltd: toMilliSecondsOrNull(flight.latest_time_of_departure),
          };

          const preparedParams = prepareTurnaroundParams(
            parsedParams,
            this._di.config.turnParamsSource
          );

          const id = `${flight.origin_date}:${flight.company_iata}:${flight.full_flight_number}:${flight.departure_airport}:${flight.arrival_airport}`;

          const result: FutureFlightV2 = {
            id,
            standId: flight.stand_id,
            flightNumber: flight.full_flight_number || "",
            companyIata: flight.company_iata,
            departureAirport: flight.departure_airport,
            arrivalAirport: flight.arrival_airport,
            aircraftType: flight.aircraft_type,
            aircraftRegNumber: flight.aircraft_reg_number,
            rawFlightStatus: flight.raw_flight_status,

            params: preparedParams,
          };

          return result;
        });
      }
    );
  }

  private _getTurnaroundsV2 = (
    params: TTurnaroundsParams = {},
    withSummary = false,
    signal?: AbortSignal
  ) => {
    type TResponse = any[];

    const url = withSummary
      ? "/api/v2/turnarounds/summary"
      : "/api/v2/turnarounds/";

    if ("turn_start_filter" in params) {
      if (params.turn_start_filter?.from) {
        params.turn_start_filter.from /= 1000;
      }

      if (params.turn_start_filter?.to) {
        params.turn_start_filter.to /= 1000;
      }
    }

    if ("param_filters" in params) {
      params.param_filters = params.param_filters?.map((filter) => {
        if (filter.from) {
          filter.from /= 1000;
        }

        if (filter.to) {
          filter.to /= 1000;
        }

        return filter;
      });
    }

    let convertedSort:
      | {
          mode: "desc" | "asc";
          param: string;
        }
      | undefined = undefined;
    if ("sort" in params && params.sort) {
      // FIXME Backend expects different names for some params
      // In the future, RE promised to unify the names
      const internalParamsToFlightParamsMapping: Record<string, string> = {
        prdt: "predicted_aircraft_ready_ts",
        ardt: "aircraft_ready_ts",
      };
      convertedSort = {
        mode: params.sort.mode,
        param:
          internalParamsToFlightParamsMapping[params.sort.param] ||
          params.sort.param,
      };
    }

    return this._request<WatchlistTurnaroundState[], TResponse>(
      {
        method: "POST",
        url,
        data: { ...params, sort: convertedSort },
        signal,
      },
      (data) => {
        const result = data.map((item) => {
          const turnaround = this._parseTurnaround(item.turnaround);
          if (!turnaround) {
            return null;
          }

          return {
            turnaround,
            lastImageTimestamp: parseTimestampsDict(item.last_image_ts),
            operationWidgets: camelCaseKeys(item.operation_widgets),
            alertsCount: item.alerts_count ?? null,
            safetyTriggered: item.safety_flag ?? null,
            alertsSummary: item.alerts_summary?.map((v: any) => ({
              severity: v.severity as IncidentSeverity,
              count: v.count,
            })),
          };
        });

        return compact(result);
      }
    );
  };

  private _parseTurnaround = (data: unknown) => {
    const { fixPOBTOutOfBounds, turnParamsSource } = this._di.config;
    return parseTurnaround(data, fixPOBTOutOfBounds, turnParamsSource);
  };

  private _parseTurnarounds = (data: unknown[]) => {
    const {
      fixPOBTOutOfBounds,
      turnaroundsStartTimestamp,
      turnaroundsVisibleRangeSizeByRole,
      turnParamsSource,
    } = this._di.config;
    const user = this._di.user;

    return parseTurnarounds(data, user, {
      fixPOBTOutOfBounds,
      turnaroundsStartTimestamp,
      turnaroundsVisibleRangeSizeByRole,
      turnParamsSource,
    });
  };

  private _getAlertsHandler(
    { incident_ids, ...params }: AlertsParams,
    targetIncidentTypes: string[],
    filterAlertsFromOtherAirports: boolean
  ) {
    const { turnaroundsStartTimestamp } = this._di.config;

    let startTs = 0;
    const { stand_id: standId } = params;

    if (isFiniteNumber(turnaroundsStartTimestamp)) {
      startTs = turnaroundsStartTimestamp;
    } else if (turnaroundsStartTimestamp && standId) {
      startTs = turnaroundsStartTimestamp[standId] || 0;
    }

    const urlSearchParams = new URLSearchParams();
    Object.entries(params).forEach(([k, v]) => {
      if (v !== undefined) {
        urlSearchParams.append(k, v.toString());
      }
    });

    // Encode query string manually, because API does not support conventional query string arrays
    // Conventional way: incident_types\[\]=safety-event&incident_types\[\]=weighted-safety-event
    // But we must do it like this: incident_type=safety-event&incident_type=weighted-safety-event
    let encodedParams = urlSearchParams.toString();
    targetIncidentTypes.forEach((incident_type) => {
      encodedParams += `&${encodeURIComponent(
        "incident_type"
      )}=${encodeURIComponent(incident_type)}`;
    });

    // Encode this query string manually too
    if (incident_ids) {
      incident_ids.forEach((incidentId) => {
        encodedParams += `&${encodeURIComponent(
          "incident_ids"
        )}=${encodeURIComponent(incidentId)}`;
      });
    }

    const url = ["/api/stands/incidents", encodedParams]
      .filter(Boolean)
      .join("?");

    type TResponse = ApronAlert[];

    return this._request<TAddAlertsResult, TResponse>(
      { url },
      ({ incidents: rawConfigs = [], total_count }: any) => {
        if (startTs) {
          rawConfigs = rawConfigs.filter(({ ts }: any) => ts >= startTs);
        }

        const result: TAddAlertsResult = {
          unifiedIncidents: [],
          safetyAlertIncidents: [],
          safetyEventIncidents: [],
          actualAlertCount: total_count,
        };

        for (const raw of rawConfigs) {
          const parsedData = parseAlert(raw);
          const data = parsedData.data;

          switch (data.incidentType) {
            case "unified": {
              result.unifiedIncidents.push({
                ...parsedData,
                data,
              });
              break;
            }

            case "safety-alert": {
              result.safetyAlertIncidents.push({
                ...parsedData,
                data,
              });
              break;
            }

            case "safety-event": {
              result.safetyEventIncidents.push({
                ...parsedData,
                data,
              });
              break;
            }
          }
        }

        if (filterAlertsFromOtherAirports) {
          const { standPatterns } = this;
          result.unifiedIncidents = filterItemsByStandPatterns(
            result.unifiedIncidents,
            (v) => v.standId,
            standPatterns
          );
          result.safetyAlertIncidents = filterItemsByStandPatterns(
            result.safetyAlertIncidents,
            (v) => v.standId,
            standPatterns
          );
          result.safetyEventIncidents = filterItemsByStandPatterns(
            result.safetyEventIncidents,
            (v) => v.standId,
            standPatterns
          );
        }

        return result;
      }
    );
  }
}
